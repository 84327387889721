body {
  font-family: 'Open Sans', sans-serif;
  color: #333;
  background: #FFF;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.title-color{
  color: #1275fa;
}

.title-font{
  font-family:'Roboto Slab', 'Helvetica', sans-serif;
}

.bgcolors{
  background: linear-gradient(2deg, rgba(53,119,242, 1), rgba(8,240,201, 1));
}

/* Vertical Centering */
.v-center{
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}


/* HOME */
.loader{
  transition: opacity .25s ease-out;
}
.loading .loader{
  opacity: 1;
}
.loaded .loader{
  opacity: 0;
}
.panel-sidebar {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  background: #FFF;
}

/* LOGIN FORM */
/* .form-signin, .form-signin .btn{
  font-size: .9rem;
} */
.form-signin .form-control{
  color: #000;
  background: transparent;
  box-shadow: none;
}
.form-signin .form-control:focus{
  border-width: 2px;
}
.form-signin .form-control.form-control-lg{
  height: 50px;
  font-size: 1.2rem;
}
.form-signin .btn-lgx{
  height: 60px;
  /* font-size: 25px; */
  color: rgba(53, 119, 242, 1);
  border: 2px solid #FFF;
  background: #FFF; 
}
.form-signin .btn-lgx:hover{
  color: #FFF;
  border: 2px solid #FFF;
  background: rgba(255, 255, 255, .2);
}

.form-signin .form-control::-webkit-input-placeholder { color: #888; font-weight: 400; }  /* WebKit, Blink, Edge */
.form-signin .form-control:-moz-placeholder { color: #888; font-weight: 400; }  /* Mozilla Firefox 4 to 18 */
.form-signin .form-control::-moz-placeholder { color: #888; font-weight: 400; }  /* Mozilla Firefox 19+ */
.form-signin .form-control:-ms-input-placeholder { color: #888; font-weight: 400; }  /* Internet Explorer 10-11 */
.form-signin .form-control::-ms-input-placeholder { color: #888; font-weight: 400; }  /* Microsoft Edge */

.pass-switch{
  position: relative;
  float: right;
  margin-top: -35px;
  margin-right: 15px;
  font-size: .9rem;
  color: rgba(50,50,50,.5);
  z-index: 100;
}
.pass-switch:hover{
  cursor: pointer;
  color: rgba(50,50,50,1);
}



.text-grey{ color: #CCC; }

/* SLIDERS */
input[type=range] {
  -webkit-appearance: none;
  margin: 10px 0;
  width: 100%;
}
input[type=range]:focus {
  outline: none;
}
input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 12px;
  margin-top:10px;
  cursor: pointer;
  background: #DDD;
  border-radius: 5px;
}
input[type=range]::-webkit-slider-thumb {
  position: relative;
  display: inline-block;
  padding: 15px;
  margin-top: -10px;
  border-radius: 50%;
  background: #3577f2;
  cursor: pointer;
  -webkit-appearance: none;
  z-index: 100;
}
input[type=range]:focus::-webkit-slider-runnable-track {}
input[type=range]::-moz-range-track {
  width: 100%;
  height: 12px;
  margin-top:10px;
  cursor: pointer;
  background: #DDD;
  border-radius: 5px;
}
input[type=range]::-moz-range-thumb {
  position: relative;
  display: inline-block;
  padding: 15px;
  margin-top: -10px;
  border-radius: 50%;
  background: #3577f2;
  cursor: pointer;
  z-index: 100;
}
input[type=range]::-ms-track {
  width: 100%;
  height: 12px;
  margin-top:10px;
  cursor: pointer;
  background: #DDD;
  border-radius: 5px;
}
input[type=range]::-ms-fill-lower {}
input[type=range]::-ms-fill-upper {}
input[type=range]::-ms-thumb {
  position: relative;
  display: inline-block;
  padding: 15px;
  margin-top: -10px;
  border-radius: 50%;
  background: #3577f2;
  cursor: pointer;
  z-index: 100;
}
input[type=range]:focus::-ms-fill-lower {}
input[type=range]:focus::-ms-fill-upper {}

input.init[type=range],
input.init[type=range],
input.init[type=range]{
  animation: pulse 1s infinite;
}






/* Navigation */
#main-menu{
  font-family:'Roboto Slab', 'Helvetica', sans-serif;
  border-bottom: 1px solid #E6E6E6;
  padding: 0;
  background: #FFF;;
}
#main-menu.affix{ 
  background: rgba(255, 255, 255, .8); 
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.1);
}
#main-menu a.navbar-brand{
  color: #333;
}

#main-menu .navbar-brand {
  font-size: 30px;
  line-height: 1;
  font-weight: 300;
}
#main-menu .navbar-brand {
  float: left;
  height: 60px;
  padding: 15px;
}

#main-menu .nav > li > a,
#main-menu > a,
.topbar .btn-link{
  font-size: 20px;
  line-height: 20px;
  font-weight: 300;
  padding: 20px;
  /* color: rgba(255,255,255,.9); */
  border: none;
  border-radius: 0;
  background: transparent;
}
#main-menu .nav > li > a, 
.topbar .btn-link{
  color: #333;
}

#main-menu .nav > li.active a,
#main-menu .nav > li.active:hover a,
#main-menu .nav > li:hover a{
  color: #FFF;
  background: rgba(53, 119, 242, 1);
}

#module-name, .navbar-brand a{ color: #333; }

.secondary-bar{ 
  border-left: 3px solid rgba(53, 119, 242);
  background: rgba(68, 70, 72, .1);
}






/* Menu Modal */
#menu-modal{
  background: rgba(71, 83, 101, 1);
}


/* Slide menu */
#slide-menu ul{
  background: #FFF;
}
#slide-menu ul li > a,
#slide-menu ul li > span{
  color: #1275fa;
  border-left: 3px solid #FFF;
}

#slide-menu ul li.active > a,
#slide-menu ul li.active > span,
#slide-menu ul li > a:hover,
#slide-menu ul li > span:hover{
  color: #444648;
  border-left: 3px solid #1275fa;
  background: #ececec;
}

#slide-menu ul .profile-info{ color: #1275fa; padding: 20px 0 10px 0; }
#slide-menu ul .close-menu{ color: #FFF; }


/* Game Setup */
#shortlink{ color: #1275fa; }

.btn.coll-toggle,
.btn.coll-toggle:hover{
  color: #EEE;
  background: #445360;
}



.jumbotron{ background: rgba(68, 70, 72, .1); }

.nav-pills>li>a{
  color: #36b3bb;
  border: 1px solid #36b2ba;
  background-color: transparent;
}

.nav-pills>li.active>a,
.nav-pills>li.active>a:focus,
.nav-pills>li.active>a:hover {
  color: #fff;
  background-color: #36b3bb;
}


/* Debrief Bar */
#inner-menu.nav-pills>li>a{
  border: none;
}
#inner-menu.nav-pills>li>a:hover{
  color: #1275fa;
}
#inner-menu.nav-pills>li.active>a,
#inner-menu.nav-pills>li.active>a:focus,
#inner-menu.nav-pills>li.active>a:hover{
  color: #1275fa;
  font-weight: 600;
  border: none;
  background: transparent;
}

.mobileframe{
  padding: 50px 50px;
  background: url('assets/mobile_frame.png') no-repeat;
  background-size: cover;
}